import React, { Component } from 'react'
import { Button, DropdownButton, MenuItem } from 'react-bootstrap'
import ScheduleVert from '../schedules/schedulevert.js'
import Spinner from '../../indicators/spinner.js'
import CommandSpinner from '../../indicators/commandspinner.js'

import api from '../../util/api.js'

const { getUserPromise, setSchedule } = api
var mapToFormat = require('../schedules/mapToFormat.js')

var apiUrl = 'https://jdpy43j0u7.execute-api.us-west-2.amazonaws.com'

function getDeviceIdFromUrlHash() {
  if (typeof window === 'undefined') return
  // https://thermostatportal.xcspec.com/#/site/6946f600-49d3-11ef-a544-cbbe36bf79b7/device/d0391e60-1896-11ef-9773-df4f403c6bf8/control
  var url = window.location.href
  var urlParts = url.split('/')
  var deviceIndex = urlParts.indexOf('device')
  return urlParts[deviceIndex + 1]
}

function getKey() {
  const key = getDeviceIdFromUrlHash()
  return `schedule-${key}`
}

function setLocalSchedule(scheduleId) {
  localStorage.setItem(getKey(), scheduleId)
}

function getLocalSchedule() {
  return localStorage.getItem(getKey())
}

class ChangeSchedule extends React.Component {
  constructor() {
    super()
    
    this.state = {
      currSelectedSchedule: null,
      scheduleObjs: [],
      activeCommandId: '',
    }
  }

  componentDidMount() {
    this.getSchedules()
  }

  getSchedules = () => {
    getUserPromise()
      .then((user) => {
        const localStoredID = getLocalSchedule()
        console.log('localStoredID', localStoredID)
        console.log('user.thermostatschedules', user.thermostatschedules)
        let selected
        if (localStoredID) {
          selected = user.thermostatschedules.find((schedule) => schedule._id === localStoredID)
        }
        console.log('selected', selected)

        this.setState({
          scheduleObjs: user.thermostatschedules,
          ...(localStoredID && selected) ? { currSelectedSchedule: selected } : {},
        })
      })
      .catch((error) => {
        console.log('ViewThermoSchedule: Error getting schedules.')
        console.log(error)
      })
  }

  handleScheduleSubmit = (e) => {
    if (this.state.currSelectedSchedule == null) {
      alert('You must select a schedule to submit.')
      return
    }
    this.setState({ apiCalling: true })
    
    setSchedule(this.props.siteID, this.props.deviceID, this.state.currSelectedSchedule._id).then((res) => {
      console.log(res)
      setLocalSchedule(this.state.currSelectedSchedule._id)

      this.setState({
        apiCalling: false,
        activeCommandId: res.data.commandId,
      })
    })
    // setSchedule(this.props.siteID, this.props.deviceID, coolScheduleForDevice)
    // .then(setSchedule(this.props.siteID, this.props.deviceID, heatScheduleForDevice))
    // .then(commandIds => {
    //   this.setState({ apiCalling: false });
    //   console.log(commandIds);
    //   //  Since setting the schedule actually consists of
    //   //  6 commands, just grab the first one and use that
    //   //  for the command spinner.
    //   this.setState({ activeCommandId: commandIds[0] });
    // });
  }

  onSelectSchedule = (eventKey) => {
    this.setState({
      currSelectedSchedule: this.state.scheduleObjs[eventKey],
    })
  }

  clearActiveCommandId = () => {
    this.setState({
      activeCommandId: '',
    })
  }

  scheduleHolidayScheduleEvent = () => {}

  render() {
    let scheduleMenuItemElements = []
    if (this.state.scheduleObjs.length > 0) {
      scheduleMenuItemElements = this.state.scheduleObjs.map((scheduleObj, index) => {
        return (
          <MenuItem key={index} onSelect={this.onSelectSchedule} eventKey={index}>
            {scheduleObj.name}
          </MenuItem>
        )
      })
    }

    return (
      <center>
        <DropdownButton id='schedules' title='Schedules' style={{ marginRight: '5px' }}>
          {scheduleMenuItemElements}
        </DropdownButton>

        <CommandSpinner
          commandId={this.state.activeCommandId}
          clearActiveCommandId={this.clearActiveCommandId}
          siteID={this.props.siteID}
          deviceID={this.props.deviceID}
          getLiveDataAfterTime={this.props.getLiveDataAfterTime}
          handleSchedulePosted={this.getSchedules}
        />
        <Button
          style={{ marginBottom: '0px', marginLeft: '5px' }}
          onClick={this.handleScheduleSubmit}
          type='button'
          className='btn btn-default'
        >
          Submit
        </Button>
        <Spinner active={this.state.apiCalling} />
        <div>
          <ScheduleVert scheduleObj={this.state.currSelectedSchedule} hideActivate={true} fillParent={true} />
        </div>
      </center>
    )
  }
}

export default ChangeSchedule
