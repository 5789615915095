import React, { Component } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

class ButtonWithToolTip extends React.Component {
  render() {
    let tooltip = (
      <Tooltip id="tooltip">
        {this.props.tooltip}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <Button 
          onClick={ this.props.onClick } 
          className={this.props.className}
          style={this.props.style}
          >
          {this.props.children}
        </Button>
      </OverlayTrigger>
    )
  }
}

export default ButtonWithToolTip;
